/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
#home {
  display: none;
}
.roothome {
  display: block;
  width: 58.07291667vw;
  margin-left: 5%;
}
#head {
  width: 90%;
  margin: 70px 5% 60px;
}
.wrapper__part {
  width: 100%;
}
.wrapper__part--fix {
  position: relative;
  height: auto;
  min-height: 0;
  border-width: 7.8125vw 7.8125vw 0;
}
.cb-layout3 .wrapper__part--fix {
  border-width: 7.8125vw 7.8125vw 0;
}
.wrapper__part--scroll {
  border-width: 0 7.8125vw 7.8125vw;
}
.cb-layout3 .wrapper__part--scroll {
  border-width: 0 7.8125vw 7.8125vw;
}
.section--multimood {
  position: relative;
  height: 468px;
  margin-top: 60px;
}
.cb-layout2 .section--multimood {
  height: auto;
  background-image: linear-gradient(to right, #F0EEED, #fff);
}
.footcontent {
  padding: 0 5%;
}
.toplink {
  right: 7.8125%;
}
.toplink.cb-goto-top-visible {
  bottom: 7.8125vw;
  margin-bottom: 20px;
}
.cb-layout2 #expo {
  display: none;
}
.batch--root {
  width: 79.42708333vw;
  height: 79.42708333vw;
  border-width: 3.90625vw;
  margin-top: -39.71354167vw;
  margin-left: -10.41666667vw;
  shape-outside: circle(43vw at 35vw 3vw);
}
.batch--root .batch__text {
  padding: 0 13.02083333vw;
}
.batch--root .batch__text--title {
  font-size: 10.41666667vw;
  margin: 3.90625vw 0;
  padding: 0 9.11458333vw;
}
.root-batch-text {
  padding: 3.90625vw;
  font-size: 4.03645833vw;
}
#root #disp.zoom {
  border: 7.8125vw solid #fff;
}
#root #disp.zoom div.prev a {
  left: 7.8125vw;
  margin-left: 3.90625vw;
}
#root #disp.zoom div.next a {
  right: 7.8125vw;
  margin-right: 3.90625vw;
}
#root #disp.zoom a.quit {
  top: 7.8125vw;
  right: 7.8125vw;
}
#root #disp.zoom div.foot {
  padding: 3.90625vw;
}
#root #disp.zoom div.foot fieldset p {
  font-size: 2.60416667vw;
}
.togglenavigation {
  top: 7.8125vw;
  right: 7.8125vw;
}
.navigation {
  padding: 0 7.8125vw;
}
body.cb-toggle-target-active .navigation {
  padding: 7.8125vw;
}
.navicontent {
  padding: 3.90625vw;
}
div.sub1 {
  width: 50%;
}
div.sub1 > .item {
  margin: 1.30208333vw 0;
}
div.sub1 > .item > .menu {
  font-size: 3.64583333vw;
}
#naviservices .meta {
  margin: 1.30208333vw 0;
  font-size: 3.64583333vw;
}
@media (min-width: 540px) {
  .cb-layout3 .wrapper {
    font-size: 2.60416667vw;
  }
  .cb-layout3 .foottitle {
    font-size: 3.64583333vw;
    padding-bottom: 2.60416667vw;
  }
  .cb-layout3 .footpart {
    margin-top: 3.90625vw;
  }
  .cb-layout3 .footpart--anfahrt {
    margin-top: 6.51041667vw;
  }
  .cb-layout3 .footpart--certificates,
  .cb-layout3 .footpart--social {
    padding: 3.90625vw 0;
  }
  .cb-layout3 .footpart--services {
    margin-bottom: 9.11458333vw;
  }
  .cb-layout3 .social .meta {
    width: 5.20833333vw;
    height: 5.20833333vw;
    margin-right: 2.60416667vw;
  }
  .cb-layout3 .social-text {
    font-size: 2.60416667vw;
  }
  .cb-layout3 #services .meta {
    font-size: 3.125vw;
    margin: 0 1.30208333vw;
  }
  .cb-layout3 h1 {
    font-size: 10.41666667vw;
  }
  .cb-layout3 h2 {
    font-size: 2.60416667vw;
  }
  .cb-layout3 .farnorth h2,
  .cb-layout3 .south h2 {
    font-size: 2.60416667vw;
  }
  .cb-layout3 h3 {
    font-size: 3.64583333vw;
  }
  .cb-layout3 .south h3 {
    font-size: 6.25vw;
  }
  .cb-layout3 h4 {
    font-size: 3.125vw;
  }
  .cb-layout3 div.east {
    margin-top: 1.953125vw;
    margin-bottom: 1.953125vw;
  }
  .cb-layout3 div.east div.unit div.link a.open {
    font-size: 2.60416667vw;
  }
  .cb-layout3 div.unit.fold {
    margin-top: 7.8125%;
    margin-bottom: 7.8125%;
  }
  .cb-layout3 div.unit.fold + .cb-layout3 div.unit.fold {
    margin-top: -7.8125%;
  }
  .cb-layout3 div.unit.fold .fold-toggle {
    padding: 2.08333333vw 0 2.08333333vw 6.51041667vw;
    background-size: 3.90625vw 3.90625vw;
  }
  .cb-layout3 div.unit.fold div.more {
    padding-bottom: 1.953125%;
  }
  .cb-layout3 div.link a.open {
    font-size: 2.34375vw;
    padding: 2.08333333vw 2.60416667vw;
  }
  .cb-layout3 div.load {
    background: none !important;
  }
  .cb-layout3 div.load + .cb-layout3 div.load {
    margin-top: 0;
  }
  .cb-layout3 div.load a.load {
    font-size: 2.60416667vw;
    padding-left: 5.20833333vw;
    background-size: 3.90625vw 3.90625vw;
  }
}
.normcontent .area {
  width: 100%;
}
.normcontent .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.normcontent .area .foot {
  margin-right: 5%;
  margin-left: 5%;
}
.normcontent .area .part {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.normcontent .area .tiny {
  width: 40%;
}
.normcontent .area > .slim {
  width: 50%;
}
.normcontent .area > .slim .foot,
.normcontent .area > .slim .part {
  margin-right: 10%;
  margin-left: 10%;
}
.normcontent .area > .slim .part {
  width: 80%;
}
.normcontent .area > .slim .tiny {
  width: 80%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .foot {
  margin-right: 5%;
  margin-left: 5%;
}
.side .part {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.side .tiny {
  width: 40%;
}
.side > .slim {
  width: 50%;
}
.side > .slim .foot,
.side > .slim .part {
  margin-right: 10%;
  margin-left: 10%;
}
.side > .slim .part {
  width: 80%;
}
.side > .slim .tiny {
  width: 80%;
}
div.farnorth {
  padding: 0 5% 20px;
}
.cb-layout2 div.farnorth {
  position: relative;
  padding-bottom: 0;
  padding-top: 20px;
  color: #7B7067;
}
div.south {
  width: 88.28125vw;
  margin-left: -1.953125vw;
}
div.east {
  margin-left: -5%;
  width: 110%;
}
.cb-layout3 div.east {
  margin-left: -5%;
  width: 110%;
}
div.south div.unit {
  margin: 1.953125vw;
  width: 95.57522124%;
  height: 84.375vw;
}
div.south div.unit.slim {
  width: 45.57522124%;
  height: 40.234375vw;
}
div.south div.seam div.body {
  padding: 2.08333333vw 3.90625vw;
}
#view div.south div.seam div.link a.open {
  background-size: 6.25vw 6.25vw;
  background-position: right 3.90625vw bottom 3.90625vw;
}
div.east div.unit {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}
div.east div.unit.slim {
  width: 40%;
}
.cb-layout3 div.east div.unit {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}
.cb-layout3 div.east div.unit.slim {
  width: 40%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */